/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
import './src/styles/global.css';
import './src/styles/custom.css';
import React from 'react';
import { QueryClientProvider } from 'react-query';
import { Helmet } from 'react-helmet';
import { Toaster } from 'react-hot-toast';
import sepirakQueryClient from '@src/apiFetch/sepirakQueryClient';

export const wrapRootElement = ({ element }) => (
    <QueryClientProvider client={sepirakQueryClient}>
        <Helmet bodyAttributes={{ class: 'font-custom' }}>
            <script type="text/javascript" id="hs-script-loader" async defer src="//js-na1.hs-scripts.com/23684445.js" />
        </Helmet>
        {element}
        <Toaster />
    </QueryClientProvider>
);

// Prevent page scrolling on url search parameters change
export const shouldUpdateScroll = ({
    routerProps: { location },
    getSavedScrollPosition,
}) => false;
